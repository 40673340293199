import React, { useState } from "react";
import RequestService from "../../services/request.service";

import { toast } from 'react-toastify'



import Pagination from "../../services/Pagination";
import { Table } from 'react-bootstrap'

import ModalEditarInformacionCiudadano from './ModalEditarInformacionCiudadano'
import ModalGestionDocumentacion from './ModalGestionDocumentacion'


const Archivos = () =>{




  const [allSessionsCount,setCounter] = useState(0);
  const sessionsPerPage = 50
  const [currentPage, setCurrentPage] = useState(1);

  const [ text_busqueda, setTextoBusqueda ] = useState('')

  const [ cargando_, setCargando ] = useState( false )

  const skull = { results: [] }

  const [ resultados, setResultados ] = useState<any>({...skull})

  const cargarMiReporte = async(page:number)=>{
    try{
      setCargando(true)
      let skip = 0
      if(page===0) skip = 0; else skip = (page*10);
      let response : any = await RequestService.getEndPoint(`anexos_personal/busqueda?where=${text_busqueda}&skip=${skip}&limit=${sessionsPerPage}`)
      setCounter(response.data.totalCount)
      setResultados(response.data.resultados)
      setCargando(false)
    }
    catch(e:any){

      if (e.response?.status) {
        switch (e.response.status) {
          case 404:
            toast.error("Debes escribir algo")
            break;
          default:
            toast.error(e.response?.data)
        }
      }
      else{
        toast.error("Problemas con la busqueda")
      }
      setCargando(false)

    }
  }


  const busqueda = async () =>{
    cargarMiReporte(currentPage===0?0:currentPage-1)
    setCurrentPage(1)
  }




      React.useEffect(() => {
        busqueda()
      }, []);




  const [ show_ModalEditarInformacionCiudadano, setModalEditarInformacionCiudadano ] = useState<boolean>(false)
  const [ ciudadano_seleccionado, setCiudadanoSeleccionado ] = useState<any>(null)
  const handleShow_ModalEditarInformacionCiudadano = (ciudadano:any) => {
    setCiudadanoSeleccionado(ciudadano)
    setModalEditarInformacionCiudadano(true)
  }
  const handleClose_ModalEditarInformacionCiudadano = () => {
    cargarMiReporte(currentPage===0?0:currentPage-1)
    setModalEditarInformacionCiudadano(false)
  }



  const [ show_ModalGestionDocumentacion, setModalGestionDocumentacion ] = useState<boolean>(false)
  const handleShow_ModalGestionDocumentacion = (ciudadano:any) => {
    setCiudadanoSeleccionado(ciudadano)
    setModalGestionDocumentacion(true)
  }
  const handleClose_ModalGestionDocumentacion = () => {
    cargarMiReporte(currentPage===0?0:currentPage-1)
    setModalGestionDocumentacion(false)
  }



      return (
          <>
            <div className="container-fluid">

              <div className="row justify-content-center">
                <div className="col-10 col-sm-11">

                    <h4>Archivos</h4>
                    <hr/>
                    



                      
                          <div className="mb-4">
                              <label className="form-label">Búsqueda</label>
                              <div className="hstack gap-3">
                                <input className="form-control me-auto" type="text"  onKeyDown={(e)=>{if(e.keyCode === 13){ busqueda() }}} value={text_busqueda} onChange={event => setTextoBusqueda(event.target.value)} />
                                <button type="button" onClick={busqueda}  className="btn btn-secondary"><i className="fa fa-search" /></button>
                                <div className="vr"></div>
                                <button type="button" onClick={(e:any)=>{setTextoBusqueda('')}} className="btn btn-outline-danger"><i className="fa fa-trash"/></button>
                              </div>
                          </div>
                          








                          {  (!cargando_) && (

                              <>

                                   {
                                     resultados.length > 0 && (

                                       <>


                                       <h5>Resultados</h5>


                                        <Table responsive="lg">
                                          <thead>
                                            <tr>
                                              <th scope="col"><small># Empleado</small></th>
                                              <th scope="col"></th>
                                              <th scope="col">CURP / RFC</th>
                                              <th scope="col">Dirección</th>
                                              <th scope="col">Colonia</th>
                                              <th scope="col">C.P.</th>
                                              <th scope="col"></th>
                                              <th scope="col"></th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {


                                              resultados.map( (ciudadano:any,indice:number) =>
                                                  <tr key={ Math.random().toString(36).substr(2, 9) } style={{fontSize:12}}>
                                                    <td className="v text-left" style={{"width":"100px"}}>
                                                        <b>{ciudadano.numero_empleado}</b>
                                                    </td>
                                                    <td className="v text-left"  >
                                                        <p className="nm"><small><b>{ciudadano.nombres}</b></small></p>
                                                        <small>{ciudadano.paterno} {ciudadano.materno}</small>
                                                    </td>
                                                    <td className="v text-left" style={{"width":"180px"}}>
                                                        <p className="nm"><b>{ciudadano.curp}</b></p>
                                                        <p className="nm"><small>{ciudadano.rfc}</small></p>
                                                    </td> 
                                                    <td className="v text-left" style={{"width":"280px"}}>
                                                        <small>{ciudadano.direccion}</small>
                                                    </td>
                                                    <td className="v text-left" style={{"width":"240px"}}>
                                                        <small>{ciudadano.colonia}</small>
                                                    </td>
                                                    <td className="v text-left" style={{"width":"40px"}}>
                                                        <small>{ciudadano.cp}</small>
                                                    </td>
                                                    <td className="v text-left" style={{"width":"40px"}}>
                                                        <button onClick={(e:any)=>{handleShow_ModalEditarInformacionCiudadano(ciudadano)}} className="btn btn-sm btn-warning"><i className="fa fa-edit"/></button>
                                                    </td>
                                                    <td className="v text-left" style={{"width":"40px"}}>
                                                        <button onClick={(e:any)=>{handleShow_ModalGestionDocumentacion(ciudadano)}} className="btn btn-sm btn-primary"><i className="fa fa-upload"/></button>
                                                    </td>
                                                  </tr>
                                               )
                                            }

                                          </tbody>
                                        </Table>


                                          <Pagination
                                            itemsCount={allSessionsCount}
                                            itemsPerPage={sessionsPerPage}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            cambiarPaginaEvento={cargarMiReporte}
                                            alwaysShown={false}
                                          />


                                        </>



                                       )
                                     
                                   }


                              </>

                           )}

                          {  cargando_ && (

                              <>
                                <div className="loader">
                                  <div className="loaderBar"></div>
                                </div>
                              </>


                           )}


                </div>
              </div>

            </div>





  {
    show_ModalEditarInformacionCiudadano && (
      <ModalEditarInformacionCiudadano show={show_ModalEditarInformacionCiudadano} handleClose={handleClose_ModalEditarInformacionCiudadano} ciudadano={ciudadano_seleccionado} />
      )

  }


  {
    show_ModalGestionDocumentacion && (
      <ModalGestionDocumentacion show={show_ModalGestionDocumentacion} handleClose={handleClose_ModalGestionDocumentacion} ciudadano={ciudadano_seleccionado}  />
      )

  }

          </>

      );
  

}

export default Archivos
