import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import {Button, Modal, Tab, Tabs } from 'react-bootstrap';

import { toast } from 'react-toastify'


import { SERVER_ } from  '../../config';



import moment from 'moment'
 

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';



const ModalFichaEmpleado = ( params : any ) => {

  const currentUser  = AuthService.getCurrentUser();



  const [ empleado, setEmpleado ] = useState<any>({ 
                movimientos_nomina : [], 
                metadata : {email:'',celular:''}, 
                usuario : { uuid : '' }, 

                info_nomina : { 
                                nomina : { 
                                  nomina : '', 
                                }, 

                                  direccion_area : { area:'' }, 
                                  departamento : { departamento : '', clave_departamento : '' }, 
                                  subnomina : { subnomina : '' } ,

                                puesto : { puesto : '' }, 
                                nivel_jerarquico : { nivel_jerarquico : "" }, 
                                grupo_funcional : { grupo_funcional : "" } 
                              }  
                })

  const { show, handleClose, uuid_empleado } = params

 

  const cargaFicha = async() => {

    try{
      let res = await RequestService.getEndPoint('recursos_humanos/ficha_trabajador/'+uuid_empleado)
      setEmpleado(res.data)
    }
    catch(e){
      toast.error('Tuvimos problemas al cargar la lista de nominas')
    }

  }


  const [key, setKey] = useState<any>('informacion_personal');


  React.useEffect(() => {
    cargaFicha()
  }, []);



   const onChangeRango = (dates:any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };



  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());



  const [_startDate, _setStartDate] = useState(new Date());
  const [_endDate, _setEndDate] = useState(new Date());


  const actualizarGafete = ()=>{
    _setStartDate(startDate)
    _setEndDate(endDate)
  }



  return (
    <>
      <Modal show={show} className="modal-xl" onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>FICHA DE EMPLEADO</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            // JSON.stringify(empleado)
          }





    
          <div className="row gutters-sm">
            <div className="col-md-4 mb-3">


              <div className="card">
                <div className="card-header">
                  Empleado
                </div>
                <div className="card-body">
                  <div className="d-flex flex-column align-items-center text-center">
                      <img src={`${SERVER_}empleados/foto/${empleado.uuid}?${Math.random().toString(36).substr(2, 9)}`}   style={{width:'100%'}} />
                    <div className="mt-3">
                      <h4>{empleado.nombres} {empleado.paterno} {empleado.materno} </h4>
                    </div>
                  </div>
                  {
                    empleado.es_padre && (
                      <div className="alert text-center alert-warning">
                        ES PADRE
                      </div>
                    )
                  }
                </div>
              </div>

              <div className="card mb-3">
                <div className="card-header">
                  Información Laboral
                </div>
                <div className="card-body">

                  <div className="row">
                    <div className="col-sm-5">
                      <h6 className="mb-0">Número de Empleado</h6>
                    </div>
                    <div className="col-sm-7 text-secondary">
                      {empleado.numero_empleado}
                    </div>
                  </div>
                  <hr/>

                  <div className="row">
                    <div className="col-sm-5">
                      <h6 className="mb-0">Puesto</h6>
                    </div>
                    <div className="col-sm-7 text-secondary">
                      {empleado.info_nomina.puesto.puesto}
                    </div>
                  </div>
                  <hr/>
                  <div className="row">
                    <div className="col-sm-5">
                      <h6 className="mb-0">Nómina</h6>
                    </div>
                    <div className="col-sm-7 text-secondary">
                      {empleado.info_nomina.nomina.nomina}
                      {
                        empleado.info_nomina.subnomina && (<p className="nm"><small>{empleado.info_nomina.subnomina.subnomina}</small></p>)
                      }
                    </div>
                  </div>
                  <hr/>

                  <div className="row">
                    <div className="col-sm-5">
                      <h6 className="mb-0">Dirección de Área</h6>
                    </div>
                    <div className="col-sm-7 text-secondary">
                      {empleado.info_nomina?.direccion_area?.direccion}
                    </div>
                  </div>
                  <hr/>
                  <div className="row">
                    <div className="col-sm-5">
                      <h6 className="mb-0">Departamento</h6>
                    </div>
                    <div className="col-sm-7 text-secondary">
                      {empleado.info_nomina.departamento.clave_departamento} {empleado.info_nomina.departamento.departamento}
                    </div>
                  </div>
                  <hr/>



                  
                  <div className="row">
                    <div className="col-sm-5">
                      <h6 className="mb-0">Grupo Funcional</h6>
                    </div>
                    <div className="col-sm-7 text-secondary">
                      {empleado.info_nomina.grupo_funcional.grupo_funcional}
                    </div>
                  </div>
                  <hr/>
                  <div className="row">
                    <div className="col-sm-5">
                      <h6 className="mb-0">Nivel Jerárquico</h6>
                    </div>
                    <div className="col-sm-7 text-secondary">
                      {empleado.info_nomina.nivel_jerarquico.nivel_jerarquico}
                    </div>
                  </div>

                  {
                    // JSON.stringify(currentUser.rol)
                  }

                  {
                    ['ROLE_ADMIN','NOMINA','EJECUTIVO_PRIMARIO'].indexOf(currentUser.rol) > -1 && (
                        <React.Fragment>
                          
                          <hr/>
                          
                          <div className="row">
                            <div className="col-sm-5">
                              <h6 className="mb-0">Sueldo</h6>
                            </div>
                            <div className="col-sm-7 text-secondary">
                              $ {empleado.info_nomina.sueldo}
                            </div>
                          </div>

                        </React.Fragment>
                      )
                  }


                </div>
              </div>

            </div>
            <div className="col-md-8">





    <Tabs
      id="controlled-tab-example"
      activeKey={key}
      onSelect={(k) => setKey(k)}
      className="mb-3"
    >
      <Tab eventKey="informacion_personal" title="Información">


              <div className="card mb-3">
                <div className="card-header">
                  Información de Personal
                </div>
                <div className="card-body">

                  <div className="row">
                    <div className="col-sm-3">
                      <h6 className="mb-0">Cumpleaños</h6>
                    </div>
                    <div className="col-sm-9 text-secondary">
                      {moment(empleado.fecha_nacimiento).format('DD [de] MMMM ').replace('.','').toUpperCase()}
                    </div>
                  </div>
                  <hr/>
                  <div className="row">
                    <div className="col-sm-3">
                      <h6 className="mb-0">C.U.R.P.</h6>
                    </div>
                    <div className="col-sm-9 text-secondary">
                      {empleado.curp}
                    </div>
                  </div>
                  <hr/>
                  <div className="row">
                    <div className="col-sm-3">
                      <h6 className="mb-0">R.F.C.</h6>
                    </div>
                    <div className="col-sm-9 text-secondary">
                      {empleado.rfc}
                    </div>
                  </div>
                  <hr/>
                  <div className="row">
                    <div className="col-sm-3">
                      <h6 className="mb-0">Código Postal Fiscal</h6>
                    </div>
                    <div className="col-sm-9 text-secondary">
                      {empleado.cp_fiscal}
                    </div>
                  </div>
                </div>
              </div>


{
  // JSON.stringify(empleado)
}


              <div className="card mb-3">
                <div className="card-header">
                  Información de Localización
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-3">
                      <h6 className="mb-0">Calle</h6>
                    </div>
                    <div className="col-sm-9 text-secondary">
                      {empleado?.direccion||''}
                    </div>
                  </div>
                  <hr/>
                  <div className="row">
                    <div className="col-sm-3">
                      <h6 className="mb-0">Número Interior</h6>
                    </div>
                    <div className="col-sm-9 text-secondary">
                      {empleado?.num_int||''}
                    </div>
                  </div>
                  <hr/>
                  <div className="row">
                    <div className="col-sm-3">
                      <h6 className="mb-0">Número Exterior</h6>
                    </div>
                    <div className="col-sm-9 text-secondary">
                      {empleado?.num_ext||''}
                    </div>
                  </div>
                  <hr/>
                  <div className="row">
                    <div className="col-sm-3">
                      <h6 className="mb-0">Colonia</h6>
                    </div>
                    <div className="col-sm-9 text-secondary">
                      {empleado?.colonia||''}
                    </div>
                  </div>
                  <hr/>
                  <div className="row">
                    <div className="col-sm-3">
                      <h6 className="mb-0">Código Postal</h6>
                    </div>
                    <div className="col-sm-9 text-secondary">
                      {empleado?.cp||''}
                    </div>
                  </div>
                </div>
              </div>



              <div className="card mb-3">
                <div className="card-header">
                  Información de Contacto
                </div>
                <div className="card-body">

                  <div className="row">
                    <div className="col-sm-3">
                      <h6 className="mb-0">Correo Electrónico</h6>
                    </div>
                    <div className="col-sm-9 text-secondary">
                      {empleado.metadata?.email||''}
                    </div>
                  </div>
                  <hr/>
                  <div className="row">
                    <div className="col-sm-3">
                      <h6 className="mb-0">Teléfono</h6>
                    </div>
                    <div className="col-sm-9 text-secondary">
                      {empleado.metadata?.celular||''}
                    </div>
                  </div>
                  <hr/>
                  <div className="row">
                    <div className="col-sm-3">
                      <h6 className="mb-0">Dirección</h6>
                    </div>
                    <div className="col-sm-9 text-secondary">
                      {empleado.direccion}
                    </div>
                  </div>
                  <hr/>
                  <div className="row">
                    <div className="col-sm-3">
                      <h6 className="mb-0">C.P.</h6>
                    </div>
                    <div className="col-sm-9 text-secondary">
                      {empleado.cp}
                    </div>
                  </div>
                </div>
              </div>


      </Tab>




                  {
                    ["ROLE_ADMIN"].indexOf(currentUser.rol) > -1 && (
                          
                            <Tab eventKey="movimientos" title="Historico de Registro">
                              {
                                // JSON.stringify(empleado.movimientos_nomina)
                              }

                              <h4>Historico de Registro en Nómina del Empleado</h4>

                                  <table className="table">
                                    <thead>
                                      <tr>
                                        <th scope="col">Movimiento</th>
                                        <th scope="col">Observaciones</th>
                                        <th scope="col">Formato</th>
                                        <th scope="col">Fecha</th>
                                      </tr>
                                    </thead>
                                    <tbody>

                                        {
                                          empleado.movimientos_nomina.map((movimiento:any)=>

                                            <tr key={ Math.random().toString(36).substr(2, 9) }>
                                              <td className="v" style={{width:'150px'}}>{movimiento.idmovimiento.movimiento}</td>
                                              <td className="v">

                                                {
                                                  [1,3].indexOf(movimiento.idmovimiento.id) > -1 && (

                                                      <>
                                                          <dl className="nm">
                                                            <dt>Plaza</dt>
                                                            <dd><small>{movimiento.idempleado_nomina.idnomina.nomina}</small></dd>
                                                            <dt>Puesto</dt>
                                                            <dd><small>{movimiento.idempleado_nomina.idpuesto.clave_puesto} - {movimiento.idempleado_nomina.idpuesto.puesto}</small></dd>
                                                            <dt>Departamento</dt>
                                                            <dd><small>{movimiento.idempleado_nomina.iddepartamento.clave_departamento} - {movimiento.idempleado_nomina.iddepartamento.departamento} </small></dd>
                                                            <dt>Sueldo</dt>
                                                            <dd><small>$ {movimiento.idempleado_nomina.sueldo} </small></dd>
                                                          </dl>
                                                        {
                                                          // JSON.stringify(movimiento.idempleado_nomina.idnomina.nomina)
                                                        }
                                                      </>

                                                  )
                                                }


                                                {
                                                  movimiento.idmovimiento.id === 2 && (
                                                    movimiento.observaciones.toUpperCase()
                                                  )
                                                }
                                              

                                                {
                                                  movimiento.idmovimiento.id === 4 && (
                                                    <>
                                                      {
                                                        movimiento.idempleado_nomina.cambio_adscripcion && (
                                                            <>
                                                              <dl className="nm">
                                                                <dt>Departamento</dt>
                                                                <dd><small>{movimiento.idempleado_nomina.iddepartamento.clave_departamento} - {movimiento.idempleado_nomina.iddepartamento.departamento} </small></dd>
                                                              </dl>
                                                            </>
                                                        )
                                                      }
                                                      {
                                                        movimiento.idempleado_nomina.cambio_puesto && (
                                                            <>
                                                              <dl className="nm">
                                                                <dt>Puesto</dt>
                                                                <dd><small>{movimiento.idempleado_nomina.idpuesto.clave_puesto} - {movimiento.idempleado_nomina.idpuesto.puesto}</small></dd>
                                                              </dl>
                                                            </>
                                                        )
                                                      }
                                                      {
                                                        movimiento.idempleado_nomina.cambio_plaza && (
                                                            <>
                                                              <dl className="nm">
                                                                <dt>Plaza</dt>
                                                                <dd><small>{movimiento.idempleado_nomina.idnomina.nomina}</small></dd>
                                                              </dl>
                                                            </>
                                                        )
                                                      }
                                                      {
                                                        movimiento.idempleado_nomina.cambio_sueldo && (
                                                            <>
                                                              <dl className="nm">
                                                                <dt>Sueldo</dt>
                                                                <dd><small>$ {movimiento.idempleado_nomina.sueldo} </small></dd>
                                                              </dl>
                                                            </>
                                                        )
                                                      }
                                                    </>
                                                  )
                                                }
                                              



                                              </td>
                                              <td className="text-center v" style={{width:'50px'}}>


                                                <a className="btn btn-link btn-primary" target="_blank" href={`${SERVER_}ficha/${movimiento.uuid}`} role="button"><i className="fa fa-file-pdf-o"/></a>

                                              
                                                {
                                                  // [1].indexOf(movimiento.idmovimiento.id) > -1 && ( <a className="btn btn-link btn-primary" target="_blank" href={`${SERVER_}ficha/${movimiento.uuid}`} role="button"><i className="fa fa-file-pdf-o"/></a> )
                                                }





                                              </td>
                                              <td className="text-center v" style={{width:'150px'}}>{moment(movimiento.fecha_movimiento).format('YYYY-MM-DD')}</td>
                                            </tr>

                                            )
                                        }

                                    </tbody>
                                  </table>

                            </Tab>


                      )
                  }








                  {
                    ["ROLE_ADMIN"].indexOf(currentUser.rol) > -1 && (

                            <Tab eventKey="gafete" title="Gafete">


                              <div className="mb-3">
                                <label className="form-label">Vigencia del Gafete</label>
                                <div className="text-center">
                                  <DatePicker onChange={onChangeRango} className="form-control" dateFormat="dd/MM/yyyy" startDate={startDate} endDate={endDate} selectsRange />
                                </div>

                              </div>

                              <button type="submit" onClick={actualizarGafete} className="btn btn-primary">Actualizar Gafete</button>

                              <iframe src={`${SERVER_}gafete/${empleado.uuid}/${_startDate}/${_endDate}`} className="mt-4" width={'100%'} height={700} ></iframe>


                            </Tab>
      
                      )
                  }





    </Tabs>






            </div>
          </div>






        </Modal.Body>
      </Modal>




 

    </>
  );
}


export default ModalFichaEmpleado;