import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'

import { Button, Modal, Form } from 'react-bootstrap';

import { toast } from 'react-toastify'



import { Formik } from 'formik';
import * as Yup from 'yup';


import Dialog from '../../services/Dialogs'


const ModalEditarInformacionCiudadano = ( params : any ) => {

  // const user = AuthService.getCurrentUser()

  const { show, handleClose, ciudadano } = params





  const dialog = new Dialog();


  // const mensajes_validacion_generico = "Este campo es obligatorio"
const schema = Yup.object().shape({
  telefono: Yup.string().nullable().matches(/^\d{10}$/, 'El teléfono debe tener 10 dígitos').notRequired(),
  celular: Yup.string().nullable().matches(/^\d{10}$/, 'El celular debe tener 10 dígitos').notRequired(),
  email: Yup.string().nullable().email('Correo electrónico inválido').notRequired(),
  direccion: Yup.string().matches(/^[a-zA-Z0-9\sáéíóúÁÉÍÓÚ.,:#\-]+$/, 'La dirección debe ser alfanumérica').notRequired(),
  colonia: Yup.string().matches(/^[a-zA-Z0-9\sáéíóúÁÉÍÓÚ.,:#\-]+$/, 'La colonia debe ser alfanumérica').notRequired(),
  cp: Yup.string().matches(/^\d{5}$/, 'El código postal debe tener 5 dígitos').notRequired(),
});

  const [ envio_server, setSeEnvio ] = useState<boolean>(false)


  const enviarFormulario = async (registro:any,event:any) =>{
        await dialog.open({
          accept: 'Si',
          cancel: 'Espera',
          dialogClass: '',
          message: '¿Estás seguro?',
          target: event.target,
          template: ''
        })
        let dialogo : any = await dialog.waitForUser()
        if(dialogo){
          try{

          setSeEnvio(true)
          let res_ = await RequestService.update( `ciudadano/actualizar_informacion/${ciudadano.uuid_ciudadano}` , { ...registro })

          if(res_){
            toast.success('La Información de la persona se actualizó correctamente')
            setTimeout(()=>{
              handleClose()
            },0)
          }
          else{
            toast.error('Problemas...')
          } 
          }
          catch(e:any){
            toast.error(e.response.data)
            // console.log(e.response.data)
          } 
          
        }
       
  }



  return (
    <>
      <Modal show={show} size="lg" onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Editar Información</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <h5>Información Personal</h5>

          <div className="row">
            <div className="col-sm-4">
              <small>Nombre(s)</small>
              <h6>{ciudadano.nombres}</h6>
            </div>
            <div className="col-sm-4">
              <small>Apellido Paterno</small>
              <h6>{ciudadano.paterno}</h6>
            </div>
            <div className="col-sm-4">
              <small>Apellido Materno</small>
              <h6>{ciudadano.materno}</h6>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-5">
              <small>CURP</small>
              <h6>{ciudadano.curp}</h6>
            </div>
            <div className="col-sm-4">
              <small>RFC</small>
              <h6>{ciudadano.rfc}</h6>
            </div>
            <div className="col-sm-3">
              <small>Número de Empleado</small>
              <h6>{ciudadano.numero_empleado}</h6>
            </div>
          </div>

    <Formik
      validationSchema={schema}
      onSubmit={(values,e)=>{enviarFormulario(values, e)}}
      initialValues={{

            telefono : ciudadano.informacion_personal.telefono, 
            celular : ciudadano.informacion_personal.celular, 
            email : ciudadano.informacion_personal.email, 

            direccion : ciudadano.direccion, 
            colonia : ciudadano.colonia, 
            cp : ciudadano.cp, 

      }}
    >
      {({
        handleSubmit,
        handleChange,
        setFieldValue,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <Form id='my-form' noValidate onSubmit={handleSubmit}>

          <h4>Información de Contacto</h4>

          {
            // JSON.stringify(values)
          }


            <Form.Group className="mb-3" controlId="direccion">
              <Form.Label>Dirección</Form.Label>
              <Form.Control
                as="textarea"
                required
                rows={4}
                name="direccion"
                value={values.direccion}
                onBlur={(e)=>{ setFieldValue('direccion', (e.target.value).toUpperCase() ) }}
                onChange={(e)=>{ setFieldValue('direccion', e.target.value ) }}
                isInvalid={!!errors.direccion}
                style={{textTransform:'uppercase'}}
              />
              <Form.Control.Feedback type="invalid"> {<>{errors.direccion}</>} </Form.Control.Feedback>
            </Form.Group>



            <div className="row">
              <div className="col-sm-8">
                            

            <Form.Group className="mb-3" controlId="colonia">
              <Form.Label>Colonia</Form.Label>
              <Form.Control
                required
                name="colonia"
                value={values.colonia}
                onBlur={(e)=>{ setFieldValue('colonia', (e.target.value).toUpperCase() ) }}
                onChange={(e)=>{ setFieldValue('colonia', e.target.value ) }}
                isInvalid={!!errors.colonia}
                style={{textTransform:'uppercase'}}
              />
              <Form.Control.Feedback type="invalid"> {<>{errors.colonia}</>} </Form.Control.Feedback>
            </Form.Group>


              </div>
              <div className="col-sm-4">

            <Form.Group className="mb-3" controlId="cp">
              <Form.Label>Código Postal</Form.Label>
              <Form.Control 
                required
                name="cp"
                value={values.cp}
                onChange={(e)=>{ setFieldValue('cp', e.target.value ) }}
                isInvalid={!!errors.cp}
              />
              <Form.Control.Feedback type="invalid"> {<>{errors.cp}</>} </Form.Control.Feedback>
            </Form.Group>

              </div>
            </div>






            <div className="row">
              <div className="col-sm-3">
                            
            <Form.Group className="mb-3" controlId="telefono">
              <Form.Label>Teléfono</Form.Label>
              <Form.Control 
                required
                name="telefono"
                value={values.telefono}
                onChange={(e)=>{ setFieldValue('telefono', e.target.value ) }}
                isInvalid={!!errors.telefono}
              />
              <Form.Control.Feedback type="invalid"> {<>{errors.telefono}</>} </Form.Control.Feedback>
            </Form.Group>
              </div>
              <div className="col-sm-3">

            <Form.Group className="mb-3" controlId="celular">
              <Form.Label>Celular</Form.Label>
              <Form.Control 
                required
                name="celular"
                value={values.celular}
                onChange={(e)=>{ setFieldValue('celular', e.target.value ) }}
                isInvalid={!!errors.celular}
              />
              <Form.Control.Feedback type="invalid"> {<>{errors.celular}</>} </Form.Control.Feedback>
            </Form.Group>
              </div>
              <div className="col-sm-6">


            <Form.Group className="mb-3" controlId="email">
              <Form.Label>Correo Electrónico</Form.Label>
              <Form.Control 
                required
                name="email"
                value={values.email}
                onChange={(e)=>{ setFieldValue('email', e.target.value ) }}
                isInvalid={!!errors.email}
              />
              <Form.Control.Feedback type="invalid"> {<>{errors.email}</>} </Form.Control.Feedback>
            </Form.Group>
              </div>
            </div>


            







        </Form>
      )}
    </Formik>






        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" form='my-form' type="submit">Guardar <i className="fa fa-save"/></Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default ModalEditarInformacionCiudadano;