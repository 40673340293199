import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import { Button, Modal, Form } from 'react-bootstrap'

import { toast } from 'react-toastify'


import { Formik } from 'formik';
import * as yup from 'yup';




import Dialog from '../../services/Dialogs'

import { rol } from "../../services/rol";



const ModalEditarUsuario = ( params : any ) => {


  const { show, handleClose, usuario } = params

  const dialog = new Dialog();


  const mensajes_validacion_generico = "Este campo es obligatorio"
  const schema = yup.object().shape({

          username : yup.string().required(mensajes_validacion_generico).matches( /^[a-zA-Z0-9._-]+$/, "Solo se permiten letras, números, sin espacios y los siguientes carácteres . _ -" ),

          rol : yup.string().required(mensajes_validacion_generico),
          iddepartamento : yup.string().required(mensajes_validacion_generico),

          nombres : yup.string().required(mensajes_validacion_generico),
          paterno : yup.string().required(mensajes_validacion_generico),
          materno : yup.string() ,

          email : yup.string().required(mensajes_validacion_generico),
          celular : yup.string().required(mensajes_validacion_generico),

  });



const [ envio_server, setSeEnvio ] = useState<boolean>(false)


const enviarFormulario = async (registro:any,event:any) =>{
      await dialog.open({
        accept: 'Si',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro?',
        target: event.target,
        template: ''
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{

        setSeEnvio(true)
        let res_ = await RequestService.create( `usuario/agregar` , { ...registro })

        if(res_){
          toast.success('El usuario se agregó correctamente')
          setTimeout(()=>{
            handleClose()
          },0)
        }
        else{
          toast.error('Problemas...')
        } 
        }
        catch(e:any){
          toast.error(e.response.data)
          // console.log(e.response.data)
        } 
        
      }
     
}



  React.useEffect(() => {
    cargarTurnados() 
  }, []);


  const [ turnados, setTurnados] = useState<any>([])

  const cargarTurnados = async() => {

    try{
      let res = await RequestService.getEndPoint('departamentos_rh')
      setTurnados(res.data)
    }
    catch(e){
      toast.error('Tuvimos problemas al cargar la lista de turnados')
    }

  }

  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Información de Usuario</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {

          }




    <Formik
      validationSchema={schema}
      onSubmit={(values,e)=>{enviarFormulario(values, e)}}
      initialValues={{

            username : "",

            iddepartamento : "",
            rol : "",

            nombres : "",
            paterno : "",
            materno : "",

            celular : "",
            email : "",


      }}
    >
      {({
        handleSubmit,
        handleChange,
        setFieldValue,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <Form id='my-form' noValidate onSubmit={handleSubmit}>


 {
  // JSON.stringify(values)
 }

            <Form.Group className="mb-3" controlId="username">
              <Form.Label>Nombre de Usuario</Form.Label>
              <Form.Control
                required
                name="username"
                value={values.username}
                onChange={(e)=>{ setFieldValue('username', e.target.value   ) }}
                isInvalid={!!errors.username}
              />
              <Form.Control.Feedback type="invalid">{errors.username}</Form.Control.Feedback>
              <Form.Text className="text-muted">La Contraseña por default será el número de celular. Una vez que tenga acceso el usuario se recomienda cambiar la contraseña.</Form.Text>
            </Form.Group>


            <Form.Group className="mb-3" controlId="rol">
              <Form.Label>Rol </Form.Label>
                <select className="form-select" value={values.rol} required name="rol" onChange={(e)=>{ setFieldValue('rol', e.target.value ) }}>


                  <option value="" disabled>Selecciona una opción</option>

                  <option value="ROLE_ADMIN">{rol('ROLE_ADMIN')}</option>
                  <option value="EJECUTIVO_PRIMARIO">{rol('EJECUTIVO_PRIMARIO')}</option>
                  <option value="MOVIMIENTOS_NOMINA">{rol('MOVIMIENTOS_NOMINA')}</option>
                  <option value="CONSTANCIAS">{rol('CONSTANCIAS')}</option>
                  <option value="INCIDENCIAS">{rol('INCIDENCIAS')}</option>
                  <option value="EJECUTIVO_SECUNDARIO">{rol('EJECUTIVO_SECUNDARIO')}</option>
                  <option value="CONTRATOS">{rol('CONTRATOS')}</option>
                  <option value="ARCHIVOS">{rol('ARCHIVOS')}</option>
                  <option value="RECEPCION">{rol('RECEPCION')}</option>
                  <option value="NOMINA">{rol('NOMINA')}</option>
                  <option value="PENSIONADOS">{rol('PENSIONADOS')}</option>

                  <option value="CARGA_FOTOS">{rol('CARGA_FOTOS')}</option>

                  
                  <option value="PLANEACION">{rol('PLANEACION')}</option>
                  <option value="PLANEACION_REVISION">{rol('PLANEACION_REVISION')}</option>
                  <option value="PLANEACION_DIRECCIONES">{rol('PLANEACION_DIRECCIONES')}</option>


{
// <option value="ROLE_ADMIN">{rol('CAPTURISTA')}</option>
// <option value="ROLE_ADMIN">{rol('USUARIO_OFICIOS')}</option>
}



{
                  // <option value="" disabled>Selecciona una opción</option>
                  // <option value="">ROL</option>
                  // <option value="ROLE_ADMIN">ADMIMISTRADOS</option>
                  // <option value="DEPTO_MOVIMIENTOS">MOVIMIENTOS DE NÓMINA</option>
                  // <option value="DEPTO_NOMINA">CREACIÓN DE LAYOUY NÓMINA</option>
                  // <option value="JEFE_DEPTO">INCIDENCIAS</option>
                  // <option value="USUARIO_OFICIOS">VISUALIZAR OFICIOS</option>
                  // <option value="DEPTO_CONTRATOS">CONTRATOS</option>
                  // <option value="ARCHIVOS">ARCHIVOS</option>
                  // <option value="DEPTO_CONSTANCIAS">CONSTANCIAS</option>
                  // <option value="CONTROL">CONTROL Y GESTIÓN</option>
                  // <option value="RECEPCION">CAPTURA DE OFICIOS</option>


                  // <option value="CAPTURISTA">CAPTURISTA</option>
                  // <option value="OFICIOS">MIS OFICIOS</option>

}




                </select>
              <Form.Control
                type="hidden"
                required
                name="iddepartamento"
                value={values.rol}
                isInvalid={!!errors.rol}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid"> {<>{errors.rol}</>} </Form.Control.Feedback>
            </Form.Group>


            <Form.Group className="mb-3" controlId="iddepartamento">
              <Form.Label>Departamento </Form.Label>
                <select className="form-select" value={values.iddepartamento} required name="iddepartamento" onChange={(e)=>{ setFieldValue('iddepartamento', e.target.value ) }}>
                  <option value="" disabled>Selecciona una opción</option>
                  { turnados.map( (turnado:any)=> <option key={ Math.random().toString(36).substr(2, 9) } value={turnado.uuid}>{turnado.clave_departamento} {turnado.departamento} </option> ) }
                </select>
              <Form.Control
                type="hidden"
                required
                name="iddepartamento"
                value={values.iddepartamento}
                isInvalid={!!errors.iddepartamento}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid"> {errors.iddepartamento  } </Form.Control.Feedback>
            </Form.Group>





            <Form.Group   className="mb-3" controlId="nombres">
              <Form.Label>Nombre(s)</Form.Label>
              <Form.Control
                required
                name="nombres"
                value={values.nombres}
                onChange={(e)=>{ setFieldValue('nombres', (e.target.value || "").toUpperCase() ) }}
                isInvalid={!!errors.nombres}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>



            <Form.Group   className="mb-3" controlId="paterno">
              <Form.Label>Apellido Paterno</Form.Label>
              <Form.Control
                required
                name="paterno"
                value={values.paterno}
                onChange={(e)=>{ setFieldValue('paterno', (e.target.value || "").toUpperCase() ) }}
                isInvalid={!!errors.paterno}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>





            <Form.Group   className="mb-3" controlId="materno">
              <Form.Label>Apellido Materno</Form.Label>
              <Form.Control
                name="materno"
                value={values.materno}
                onChange={(e)=>{ setFieldValue('materno', (e.target.value || "").toUpperCase() ) }}
                isInvalid={!!errors.materno}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>









            <Form.Group   className="mb-3" controlId="email">
              <Form.Label>Correo Electrónico</Form.Label>
              <Form.Control
                required
                name="email"
                value={values.email}
                onChange={(e)=>{ setFieldValue('email',e.target.value ) }}
                isInvalid={!!errors.email}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>




            <Form.Group   className="mb-3" controlId="celular">
              <Form.Label>Celular</Form.Label>
              <Form.Control
                required
                name="celular"
                value={values.celular}
                onChange={(e)=>{ setFieldValue('celular', (e.target.value || "").toUpperCase() ) }}
                isInvalid={!!errors.celular}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>




 


        </Form>
      )}
    </Formik>



        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" form='my-form' type="submit">Guardar <i className="fa fa-save"/></Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default ModalEditarUsuario;