import React, { useState } from 'react';

import RequestService from "../../services/request.service";



import { Form, Col, Row } from 'react-bootstrap'

import { Formik } from 'formik';
import * as yup from 'yup';


import { toast } from 'react-toastify'


import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';


import moment from 'moment'


import Dialog from '../../services/Dialogs'
import { Button, Modal } from 'react-bootstrap'


import { curp2date, REG_CURP, REG_RFC, REG_NOMBRES, REG_SS } from '../../services/funcionesPublicas'


const mensaes_validacion_generico = 'Este campo es obligatorio'


const ModalEditarDatosEmpleado = ( params : any ) => {

  const { show, handleClose, empleado } = params

  const dialog = new Dialog();


const schema = yup.object().shape({

  curp: yup.string().required(mensaes_validacion_generico).matches( REG_CURP , "Formato de CURP invalido").required('El CURP es un campo obligatorio'),
  rfc: yup.string().required(mensaes_validacion_generico).matches( REG_RFC , "Formato de RFC invalido").required('El RFC es un campo obligatorio'),
  email: yup.string().email('Formato de Email no valido'),
  celular: yup.string().matches(/^\d{10}$/,"Formato no valido"),

  nombres: yup.string().matches( REG_NOMBRES , "Formato de nombre no valido").required(mensaes_validacion_generico),
  paterno: yup.string().matches( REG_NOMBRES , "Formato de nombre no valido").required(mensaes_validacion_generico),
  materno: yup.string().matches( REG_NOMBRES , "Formato de nombre no valido"),

  estado_civil: yup.string().required(mensaes_validacion_generico),
  seguro_social: yup.string().matches( REG_SS ,'El formato de Número de Seguro Social no es correcto'),

  direccion: yup.string(),
  colonia: yup.string(),
  cp: yup.string().required(mensaes_validacion_generico),

  cp_fiscal: yup.string().required(mensaes_validacion_generico),
  idregimen: yup.number(),

  // fecha_ingreso: yup.string().required(),
  asegurado: yup.bool(),
  es_padre: yup.bool(),

  zona : yup.number().required(mensaes_validacion_generico),
  region : yup.number().required(mensaes_validacion_generico),


});

const [ envio_server, setSeEnvio ] = useState<boolean>(false)

const enviarFormulario = async (nuevo_usuario:any,event:any,) =>{
      await dialog.open({
        accept: 'Sí, Actualizar',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro de la información?',
        // soundAccept: 'https://assets.stoumann.dk/audio/accept.mp3',
        // soundOpen: 'https://assets.stoumann.dk/audio/open.mp3',
        target: event.target,
        template: `
          <dl>
            <dt>Nombre</dt>
            <dd>`+nuevo_usuario.nombres+` `+nuevo_usuario.paterno+` `+nuevo_usuario.materno+`</dd>
            <dt>CURP</dt>
            <dd>`+nuevo_usuario.curp+`</dd>
            <dt>RFC</dt>
            <dd>`+nuevo_usuario.rfc+`</dd>
            <dt>Celular</dt>
            <dd>`+(nuevo_usuario.celular||'No Proporcionado')+`</dd>
            <dt>Correo Electrónico</dt>
            <dd>`+(nuevo_usuario.email||'No Proporcionado')+`</dd>
          </dl>
        `
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{


        setSeEnvio(true)
      // console.log('lo que se envia es:', nuevo_usuario)
        let res_nuevo_usuario = await RequestService.update(`empleado/actualizar/${empleado.uuid_ciudadano}`, {   ...nuevo_usuario, 
                                                                                                                  // fecha_ingreso : moment(nuevo_usuario.fecha_ingreso).format('YYYY-MM-DD'), 
                                                                                                                  fecha_nacimiento : curp2date(nuevo_usuario.curp), 
                                                                                                                  sexo : nuevo_usuario.curp.substr(10,1)==='H'?'H':'M' 
                                                                                                              })

        if(res_nuevo_usuario){
          toast.warning('La información de este empleado fue actualizada')
          setTimeout(()=>{
            handleClose()
          },0)
        }
        else{
          toast.error('Error al dar de alta.')
        } 
        }
        catch(e:any){
          toast.error(e.response.data)
          // console.log(e.response.data)
        } 
        
      }
     
}

  const [ regimenes, setRegimenes] = useState<any>([])

  const cargarRegimenes = async() => {

    try{
      let res = await RequestService.getEndPoint('regimenes/lista')
      setRegimenes(res.data)
    }
    catch(e){
      toast.error('Tuvimos problemas al cargar la lista de regimenes')
    }

  }

  React.useEffect(() => {
    cargarRegimenes()
  }, []);




  const [ show_ModalAltaCiudadanoSeleccionColonia, setModalAltaCiudadanoSeleccionColonia ] = useState<boolean>(false)
  const handleShow_ModalAltaCiudadanoSeleccionColonia = () => {
    setModalAltaCiudadanoSeleccionColonia(true)
  }
  const handleClose_ModalAltaCiudadanoSeleccionColonia = () => {
    setModalAltaCiudadanoSeleccionColonia(false)
  }




  return (
    <>
      <Modal show={show} size="lg" onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Modificar Información *</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            // JSON.stringify(empleado)
          }





    <Formik
      validationSchema={schema}
      onSubmit={(values,e)=>{enviarFormulario(values, e)}}
      initialValues={{

      direccion: empleado.direccion,
      num_int:  empleado.num_int ? empleado.num_int : '',
      num_ext:  empleado.num_ext ? empleado.num_ext : '',
      colonia:  empleado.colonia,
      cp:  empleado.cp,

      celular:  empleado.informacion_personal?.celular || '',
      email:  empleado.informacion_personal?.email || '',

      curp:  empleado.curp,
      rfc:  empleado.rfc,
      nombres:  empleado.nombres,
      paterno:  empleado.paterno,
      materno:  empleado.materno,
      estado_civil:  empleado.estado_civil,
      seguro_social:  empleado.seguro_social || '',

      cp_fiscal:  empleado.cp_fiscal,
      idregimen:  empleado.idregimen,

        // fecha_ingreso:  new Date(moment(empleado.fecha_ingreso).toString()),
        // fecha_ingreso: new Date(),
        asegurado: empleado.asegurado,
        es_padre: empleado.es_padre,

        zona: !empleado.zona ? '' : empleado.zona,
        region: !empleado.region ? '' : empleado.region,




      }}
    >
      {({
        handleSubmit,
        handleChange,
        setFieldValue,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <Form id='my-form' noValidate onSubmit={handleSubmit}>

        

          <h5>Información Personal</h5>

          <Row >
            <Form.Group as={Col} md="4" className="mb-3" controlId="nombres">
              <Form.Label>Nombre(s)</Form.Label>
              <Form.Control
                type="text"
                required
                name="nombres"
                value={values.nombres}
                onChange={(e)=>{ setFieldValue('nombres', (e.target.value || "").toUpperCase()) }}
                isInvalid={!!errors.nombres}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="4" className="mb-3" controlId="paterno">
              <Form.Label>Apellido Paterno</Form.Label>
              <Form.Control
                type="text"
                required
                name="paterno"
                value={values.paterno}
                onChange={(e)=>{ setFieldValue('paterno', (e.target.value || "").toUpperCase()) }}
                isInvalid={!!errors.paterno}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="4" className="mb-3" controlId="materno">
              <Form.Label>Apellido Materno</Form.Label>
              <Form.Control
                type="text"
                name="materno"
                value={values.materno}
                onChange={(e)=>{ setFieldValue('materno', (e.target.value || "").toUpperCase()) }}
                isInvalid={!!errors.materno}
              />

              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>
          </Row>



          <Row >
            <Form.Group as={Col} md="6" className="mb-3" controlId="curp">
              <Form.Label>CURP </Form.Label>
              <Form.Control
                type="text"
                required
                name="curp"
                value={values.curp}
                onChange={(e)=>{ setFieldValue('curp', (e.target.value || "").toUpperCase()) }}
                isInvalid={!!errors.curp}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>
            <Col md="3">
              { 
                REG_CURP.test(values.curp) &&(
              <div className="mb-3">
                <label className="form-label">Sexo</label>
                <input type="text" readOnly value={values.curp.substr(10,1)==='H'?'HOMBRE':'MUJER'} className="form-control" />
              </div>
                )
              }
            </Col>
            <Col md="3">
              { 
                REG_CURP.test(values.curp) &&(
              <div className="mb-3">
                <label className="form-label">Fecha de Nacimiento</label>
                <input type="text" readOnly value={curp2date(values.curp)} className="form-control" />
              </div>
                )
              }
            </Col>
          </Row>


          <Row >

            <Form.Group as={Col} md="4" className="mb-3" controlId="rfc">
              <Form.Label>RFC </Form.Label>
              <Form.Control
                type="text"
                required
                name="rfc"
                value={values.rfc}
                onChange={(e)=>{ setFieldValue('rfc', (e.target.value || "").toUpperCase()) }}
                isInvalid={!!errors.rfc}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="3" className="mb-3" controlId="cp_fiscal">
              <Form.Label>Código Postal Fiscal</Form.Label>
              <Form.Control
                type="text"
                required
                name="cp_fiscal"
                value={values.cp_fiscal}
                onChange={(e)=>{ setFieldValue('cp_fiscal',e.target.value) }}
                isInvalid={!!errors.cp_fiscal}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="5" className="mb-3" controlId="rfc">
              <Form.Label>Estado Civil </Form.Label>
                <select className="form-select" value={values.estado_civil} required name="estado_civil" onChange={(e)=>{ setFieldValue('estado_civil', (e.target.value || "").toUpperCase()) }}>
                  <option value="" disabled>Selecciona una opción</option>
                  <option value="SOLTERO">SOLTERO</option>
                  <option value="CASADO">CASADO</option>
                  <option value="DIVORCIADO">DIVORCIADO</option>
                  <option value="SEPARACIÓN EN PROCESO JUDICIAL">SEPARACIÓN EN PROCESO JUDICIAL</option>
                  <option value="VIUDO">VIUDO</option>
                  <option value="CONCUBINATO">CONCUBINATO</option>
                </select>
              <Form.Control
                type="hidden"
                required
                name="estado_civil"
                value={values.estado_civil}
                isInvalid={!!errors.estado_civil}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>
          </Row>




          <Row>
            <Form.Group as={Col} md="4" className="mb-3" controlId="direccion">
              <Form.Label>Seguro Social</Form.Label>
              <Form.Control
                type="text"
                name="seguro_social"
                value={values.seguro_social}
                onChange={(e)=>{ setFieldValue('seguro_social', e.target.value.toUpperCase() ) }}
                isInvalid={!!errors.seguro_social}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="8" className="mb-3" controlId="rfc">
              <Form.Label>Selección de Regimen </Form.Label>
                <select className="form-select" value={values.idregimen} required name="idregimen" onChange={(e)=>{ setFieldValue('idregimen', (e.target.value) ); }}>
                            {
                              regimenes.map( (regimen:any) =>
                                  <option key={ Math.random().toString(36).substr(2, 9) } value={regimen.id}>{regimen.regimen}</option>
                                )
                            }
                </select>
              <Form.Control
                type="hidden"
                required
                name="idregimen"
                value={values.idregimen}
                isInvalid={!!errors.idregimen}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>
          </Row>


          <Form.Group className="mb-3">
            <Form.Check
              inline
              name="asegurado"
              checked={values.asegurado}
              label="El empleado está asegurado"
              onChange={handleChange}
              isInvalid={!!errors.asegurado}
              feedbackType="invalid"
              id="asegurado"
            />

            <Form.Check
              inline
              name="es_padre"
              checked={values.es_padre}
              label="Es padre o madre"
              onChange={handleChange}
              isInvalid={!!errors.es_padre}
              feedbackType="invalid"
              id="es_padre"
            />
          </Form.Group>

                <hr className="border border-primary border-3 opacity-75 mb-4"/>


          <h5>Información de Contacto</h5>



          <Row>
            <Form.Group as={Col} md="7" className="mb-3" controlId="email">
              <Form.Label>Correo Electrónico</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={values.email}
                onChange={(e)=>{ setFieldValue('email', e.target.value ) }}
                isInvalid={!!errors.email}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="5" className="mb-3" controlId="celular">
              <Form.Label>Celular</Form.Label>
              <Form.Control
                type="text"
                name="celular"
                value={values.celular}
                onChange={(e)=>{ setFieldValue('celular',e.target.value) }}
                isInvalid={!!errors.celular}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>
          </Row>



          <Row>
            <Form.Group as={Col} md="8" className="mb-3" controlId="direccion">
              <Form.Label>Dirección</Form.Label>
              <Form.Control
                type="textarea"
                required
                name="direccion"
                value={values.direccion}
                onChange={(e)=>{ setFieldValue('direccion', e.target.value.toUpperCase() ) }}
                isInvalid={!!errors.direccion}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid"> {<>{errors.direccion}</>} </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="2" className="mb-3" controlId="num_int">
              <Form.Label>Núm Interior</Form.Label>
              <Form.Control
                type="text"
                name="num_int"
                value={values.num_int}
                onChange={(e)=>{ setFieldValue('num_int', e.target.value.toUpperCase() ) }}
                isInvalid={!!errors.num_int}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid"> {<>{errors.num_int}</>} </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="2" className="mb-3" controlId="num_ext">
              <Form.Label>Núm Exterior</Form.Label>
              <Form.Control
                type="text"
                name="num_ext"
                value={values.num_ext}
                onChange={(e)=>{ setFieldValue('num_ext', e.target.value.toUpperCase() ) }}
                isInvalid={!!errors.num_ext}
              />
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid"> {<>{errors.num_ext}</>} </Form.Control.Feedback>
            </Form.Group>
          </Row>




          <Row>
            <Form.Group as={Col} md="9" className="mb-3" controlId="colonia">
              <Form.Label>Colonia</Form.Label>
              <Form.Control
                type="text"
                required
                name="colonia"
                disabled
                value={values.colonia}
                onChange={(e)=>{ setFieldValue('colonia', e.target.value.toUpperCase() ) }}
                isInvalid={!!errors.colonia}
              />
              <Form.Control.Feedback type="invalid"> {<>{errors.colonia}</>} </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md="3" className="mb-3" controlId="cp">
              <Form.Label>Código Postal</Form.Label>
              <Form.Control
                type="text"
                required
                disabled
                name="cp"
                value={values.cp}
                onChange={(e)=>{ setFieldValue('cp',e.target.value) }}
                isInvalid={!!errors.cp}
              />
              <Form.Control.Feedback type="invalid"> {<>{errors.cp}</>} </Form.Control.Feedback>
            </Form.Group>
          </Row>


          <p><a className="btn btn-sm btn-link" onClick={(e:any)=>{handleShow_ModalAltaCiudadanoSeleccionColonia()}}>Seleccionar Colonia</a></p>


          {
            show_ModalAltaCiudadanoSeleccionColonia && (
              <ModalAltaCiudadanoSeleccionColonia show={show_ModalAltaCiudadanoSeleccionColonia} handleClose={handleClose_ModalAltaCiudadanoSeleccionColonia} setFieldValue={setFieldValue} />
              )

          }





{
          // <Row>
          //   <Form.Group as={Col} md="3" className="mb-3" controlId="zona">
          //     <Form.Label>Zona</Form.Label>
          //     <Form.Control
          //       type="number"
          //       required
          //       name="zona"
          //       value={values.zona}
          //       onChange={(e)=>{ setFieldValue('zona', e.target.value ) }}
          //       isInvalid={!!errors.zona}
          //     />
          //     <Form.Control.Feedback type="invalid"> {<>{errors.zona}</>} </Form.Control.Feedback>
          //   </Form.Group>
          //   <Form.Group as={Col} md="3" className="mb-3" controlId="region">
          //     <Form.Label>Región</Form.Label>
          //     <Form.Control
          //       type="number"
          //       required
          //       name="region"
          //       value={values.region}
          //       onChange={(e)=>{ setFieldValue('region', e.target.value ) }}
          //       isInvalid={!!errors.region}
          //     />
          //     <Form.Control.Feedback type="invalid"> {<>{errors.region}</>} </Form.Control.Feedback>
          //   </Form.Group>
          // </Row>

}
{
          // <Row>
          //   <Col md="8">

          //   </Col>
          //   <Col md="4">
          //     <Form.Label>Fecha de Ingreso</Form.Label>
          //     <DatePicker selected={values.fecha_ingreso} className="form-control" inline dateFormat="yyyy-MM-dd" onChange={(date:any) => setFieldValue('fecha_ingreso', date ) } />
          //   </Col>
          // </Row>
}

          
        </Form>
      )}
    </Formik>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" form='my-form' type="submit">Guardar <i className="fa fa-save"/></Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}






const ModalAltaCiudadanoSeleccionColonia = ( params : any ) => {

  // const user = AuthService.getCurrentUser()

  const { show, handleClose, setFieldValue } = params




  const dialog = new Dialog();


  const mensajes_validacion_generico = "Este campo es obligatorio"
  const schema = yup.object().shape({
          colonia : yup.string().required(mensajes_validacion_generico),
  });


  const [ envio_server, setSeEnvio ] = useState<boolean>(false)

  const enviarFormulario = async (registro:any,event:any) =>{
        await dialog.open({
          accept: 'Si',
          cancel: 'Espera',
          dialogClass: '',
          message: '¿Estás seguro de la información?',
          target: event.target,
          template: ''
        })
        let dialogo : any = await dialog.waitForUser()
        if(dialogo){
          try{

            setFieldValue('cp',registro.colonia.split('@')[1])
            setFieldValue('colonia',registro.colonia.split('@')[0])

            handleClose()

          }
          catch(e:any){
            toast.error(e.response.data)
            // console.log(e.response.data)
          } 
          
        }
       
  }


  const [ colonias, setColonias ] = useState<any>([])
  const cargarColonias = async()=>{
    try{
      setSeEnvio(true)
      let response : any = await RequestService.getEndPoint(`colonias_cp/${cp}`)
      toast.success('Puedes seleccionar una Colonia de la lista')
      setColonias(response.data)
      setSeEnvio(false)
    }
    catch(e){
      setSeEnvio(false)
      toast.error('Problemas al intentar cargar la lista de colonias')
    }
  }


  const [ cp_seleccionado, setCPSeleccionado ] = useState<any>(false)
  const [ cp, setCP ] = useState('')



  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Selección de Colonia</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            // JSON.stringify(colonias)
          }

          <div className="input-group mb-3">
            <input type="text" className="form-control" value={cp} onChange={(e:any)=>{setCP(e.target.value)}} />
            <button onClick={cargarColonias} className="btn btn-outline-secondary" type="button" id="button-addon2">Buscar Colonia</button>
          </div>

              <Formik
                validationSchema={schema}
                onSubmit={(values,e)=>{enviarFormulario(values, e)}}
                initialValues={{
                      colonia : ''
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  setFieldValue,
                  handleBlur,
                  values,
                  touched,
                  isValid,
                  errors,
                }) => (
                  <Form id='my-form-colonia' noValidate onSubmit={handleSubmit}>


                    <Form.Group  className="mb-3" controlId="colonia">
                      <Form.Label>Colonia</Form.Label>
                      <Form.Select required value={values.colonia} onChange={(e)=>{ setFieldValue('colonia',e.target.value) }} isInvalid={!!errors.colonia} >
                        <option>Selecciona una Colonia</option>
                            {
                              colonias.map((colonia:any,indice_colonia:number)=>
                                  <option key={indice_colonia} value={colonia.asentamiento +'@'+colonia.cp}>{colonia.asentamiento}</option>
                                )
                            }
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">{errors.colonia} </Form.Control.Feedback>
                    </Form.Group>


                  </Form>
                )}
              </Formik>


        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" form='my-form-colonia' type="submit">Guardar <i className="fa fa-save"/></Button>
        </Modal.Footer>

      </Modal>
    </>
  );
}

export default ModalEditarDatosEmpleado;