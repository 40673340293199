import React, { useState } from 'react';

import RequestService from "../../services/request.service";

import AuthService from '../../features/auth/auth.service'




import { Form, Col, Row } from 'react-bootstrap'

import { Formik } from 'formik';
import * as yup from 'yup';


import { toast } from 'react-toastify'


import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';


import moment from 'moment'


import Dialog from '../../services/Dialogs'
import { Button, Modal } from 'react-bootstrap'

import AsyncSelect from 'react-select/async';

import { SERVER_ } from  '../../config';


var mensaes_validacion_generico = 'Este campo es obligatorio'





const ModalEditarInformacionNominaEmpleado = ( params : any ) => {

  const { show, handleClose, empleado } = params

  const dialog = new Dialog();


const schema = yup.object().shape({

  numero_empleado: yup.string(),

  uuid_nomina: yup.string(),

    idsubnomina: yup.number().positive().nullable(true).transform(v => v === '' ? null : v)
    .when(["09407ce0-0b8e-409a-9cf3-2dc29c9d9e9d", "50599e55-84f7-471d-bb3a-f5ff4b6d9b40"], {
      is: (esBase:any, esOtro:any) => esBase || esOtro,
      then: yup.number().required('Select product'),
    }),

  uuid_puesto: yup.string().required(mensaes_validacion_generico),
  uuid_departamento: yup.string().required(mensaes_validacion_generico),

  p001: yup.number().required(mensaes_validacion_generico)
            .test('is-full-name', 'El concepto no debe pasarse del monto', function (value:number=0) {
                // console.log(puesto_seleccionado.sueldo_base_min)
                // console.log(puesto_seleccionado.sueldo_base_max)
                return ( value >= puesto_seleccionado.sueldo_base_min && value <= puesto_seleccionado.sueldo_base_max );
              }),
  p002: yup.number().required(mensaes_validacion_generico),
  p003: yup.number().required(mensaes_validacion_generico)
            .test('is-full-name', 'El concepto no debe pasarse del monto', function (value:number=0) {
                // console.log(puesto_seleccionado.sueldo_base_min)
                // console.log(puesto_seleccionado.sueldo_base_max)
                return ( value >= 0 && value <= puesto_seleccionado.compensacion_max );
              }),
  p025: yup.number().required(mensaes_validacion_generico)
            .test('is-full-name', 'El concepto no debe pasarse del monto', function (value:number=0) {
                // console.log(puesto_seleccionado.sueldo_base_min)
                // console.log(puesto_seleccionado.sueldo_base_max)
                return ( value >= 0 && value <= puesto_seleccionado.psm );
              }),
  sueldo: yup.number().required(mensaes_validacion_generico)
          .test('is-full-name', 'El Sueldo debe ser mayor a 0 pero no puede pasarse del límite', function (value:number=0) {
                // const nameArr = value.split(" ");
                return value <= puesto_seleccionado.total_percepciones_asegurado_max
              }),

  alta_imss: yup.bool(),
  fecha_ingreso: yup.string().required(),




});

  const [ puesto_seleccionado, setPuestoSeleccion ] = useState<any>({ ...empleado.puesto })


  const sumaPes = ( p001 : any, p002 : any, p003 : any, p025 : any, setFieldValue : any ) =>{

    setFieldValue('sueldo', (parseFloat(p001) + parseFloat(p002) + parseFloat(p003) + parseFloat(p025)) )

  }




  const [ nominas, setNominas] = useState<any>([])

  const cargarNominas = async() => {

    try{
      let res = await RequestService.getEndPoint('nominas/lista')
      setNominas(res.data)
    }
    catch(e){
      toast.error('Tuvimos problemas al cargar la lista de nominas')
    }

  }




  const [ grupos_funcional, setGruposFuncional] = useState<any>([{ puestos : [] }])

  const cargarPuestos = async() => {

    try{
      let res = await RequestService.getEndPoint('puestos/lista')
      setGruposFuncional(res.data)
    }
    catch(e){
      toast.error('Tuvimos problemas al cargar la lista de nominas')
    }

  }




  React.useEffect(() => {
    cargarNominas()
    cargarPuestos()
  }, []);




const [ envio_server, setSeEnvio ] = useState<boolean>(false)

const enviarFormulario = async (nuevo_usuario:any,event:any,) =>{

      await dialog.open({
        accept: 'Actualizar',
        cancel: 'Espera',
        dialogClass: '',
        message: '¿Estás seguro de la información?',
        // soundAccept: 'https://assets.stoumann.dk/audio/accept.mp3',
        // soundOpen: 'https://assets.stoumann.dk/audio/open.mp3',
        target: event.target,
        template: ``
      })
      let dialogo : any = await dialog.waitForUser()
      if(dialogo){
        try{


        setSeEnvio(true)
      // console.log('lo que se envia es:', nuevo_usuario)
        let res_nuevo_usuario = await RequestService.update(`empleado/actualizar_datos_nomina/${empleado.uuid_ciudadano}`, {   ...nuevo_usuario })

        if(res_nuevo_usuario){
          toast.warning('La información de este empleado fue actualizada')
          setTimeout(()=>{
            // handleClose()
          },3000)
        }
        else{
          toast.error('Error al dar de alta.')
        } 
        }
        catch(e:any){
          console.log(e.response)
          toast.error(e.response.data)
        } 
        
      }
     
}





  const [selectedDepartamentos, setSelectedDepartamentos] = useState([{ value : empleado.departamento.uuid, label : empleado.departamento.clave_departamento + ' - '+ empleado.departamento.departamento }]);

  const loadOptionsDepartamentos = async (inputValue:any) => {
    if (!inputValue) {
      return [];
    }

    try {
      const response = await fetch(`${SERVER_}catalogo/departamentos?q=${inputValue.toUpperCase()}`, { method: 'GET', headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${AuthService.getToken()}` } })

      const data = await response.json();

      const options = data.map((departamento:any) => ({
        label: `${departamento.clave_departamento} - ${departamento.departamento} `, 
        value: departamento.uuid
      }));

      return options;
    } catch (error) {
      console.error("Error al cargar los departamentos:", error);
      return [];
    }
  };





  return (
    <>
      <Modal show={show} size="lg" onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Editar la información de Nómina</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {

          }
            <div className="alert alert-primary" role="alert">
              Los cambios en la información no serán registrados como un movimiento
            </div>

          <h5>Información Personal</h5>

          <div className="row">
            <div className="col-sm-4">
              <small>Nombre(s)</small>
              <h6>{empleado.nombres}</h6>
            </div>
            <div className="col-sm-4">
              <small>Apellido Paterno</small>
              <h6>{empleado.paterno}</h6>
            </div>
            <div className="col-sm-4">
              <small>Apellido Materno</small>
              <h6>{empleado.materno}</h6>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-5">
              <small>CURP</small>
              <h6>{empleado.curp}</h6>
            </div>
            <div className="col-sm-4">
              <small>RFC</small>
              <h6>{empleado.rfc}</h6>
            </div>
          </div>


                <hr className="border border-primary border-3 opacity-75 mb-4"/>




    <Formik
      validationSchema={schema}
      onSubmit={(values,e)=>{enviarFormulario(values, e)}}
      initialValues={{


          numero_empleado : empleado.numero_empleado,

          uuid_nomina : empleado.nomina.uuid,
          uuid_puesto :  JSON.stringify(empleado.puesto),
          uuid_departamento :  empleado.departamento.uuid,

          idsubnomina : empleado.idsubnomina ? empleado.idsubnomina.id : null,

          p001 : empleado.p001,
          p002 : empleado.p002,
          p003 : empleado.p003,
          p025 : empleado.p025,
          sueldo : empleado.sueldo,
          alta_imss : empleado.alta_imss,

          fecha_ingreso:  new Date(moment(empleado.fecha_ingreso).toString()),

      }}
    >
      {({
        handleSubmit,
        handleChange,
        setFieldValue,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <Form id='my-form' noValidate onSubmit={handleSubmit}>

{
  // JSON.stringify(empleado)
}



          <h5>Nómina</h5>



                <Form.Group as={Col} md="3" className="mb-3" controlId="numero_empleado">
                  <Form.Label>Número de Empleado</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    name="numero_empleado"
                    value={values.numero_empleado}
                    onChange={(e)=>{  setFieldValue('numero_empleado', e.target.value ); }}
                    isInvalid={!!errors.numero_empleado}
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>

          <Row>


                <Form.Group as={Col} md="4" className="mb-3" controlId="rfc">
                  <Form.Label>Selección de Plaza </Form.Label>
                    <select className="form-select" value={values.uuid_nomina} name="uuid_nomina" onChange={(e)=>{ setFieldValue('uuid_nomina', e.target.value); setFieldValue('idsubnomina', ( ["09407ce0-0b8e-409a-9cf3-2dc29c9d9e9d","50599e55-84f7-471d-bb3a-f5ff4b6d9b40"].indexOf(e.target.value) > -1 ? null : 0 )) }}>
                      <option value="" disabled>Selecciona la nómina en donde se agrega trabajador</option>
                      { nominas.map( (nomina:any)=>  <option key={ Math.random().toString(36).substr(2, 9) } value={nomina.uuid}>{nomina.nomina}</option> ) }
                    </select>
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>


                <Form.Group as={Col} md="8" className="mb-3" controlId="uuid_departamento">
                  <Form.Label>Selección de Departamento </Form.Label>

                  <AsyncSelect
                    cacheOptions
                    loadOptions={loadOptionsDepartamentos}  // Cargar las opciones con el término de búsqueda
                    onChange={(selected:any) => {
                      setSelectedDepartamentos(selected || []);  // Guarda las opciones seleccionadas en el estado
                      setFieldValue('uuid_departamento',selected.value)
                    }}  // Actualiza el estado con las opciones seleccionadas
                    value={selectedDepartamentos}     // Estado actual de las opciones seleccionadas
                    getOptionLabel={(e:any) => e.label} // Mostrar el texto de la opción
                    getOptionValue={(e:any) => e.value} // Usar el valor de la opción para almacenarlo
                    placeholder="Escribe para buscar departamentos"
                  />

                  <Form.Control
                    type="hidden"
                    required
                    name="uuid_departamento"
                    value={values.uuid_departamento}
                    isInvalid={!!errors.uuid_departamento}
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid"> {<>{errors.uuid_departamento}</>} </Form.Control.Feedback>
                </Form.Group>

{
                // <Form.Group as={Col} md="8" className="mb-3" controlId="uuid_departamento">
                //   <Form.Label>Selección de Departamento </Form.Label>
                //     <select className="form-select" value={values.uuid_departamento} required name="uuid_departamento" onChange={(e)=>{ setFieldValue('uuid_departamento', ( e.target.value ) ) }}>
                //       <option value="" disabled>Selecciona un departamento para el trabajador</option>
                //       { 
                //         departamentos.map( (direccion_deptos:any)=>
                //             <optgroup key={ Math.random().toString(36).substr(2, 9) } label={direccion_deptos.direccion}>
                //                 {
                //                   direccion_deptos.departamentos.map( (departamento:any) =>
                //                       <option key={ Math.random().toString(36).substr(2, 9) } value={departamento.uuid}>{departamento.clave_departamento} - {departamento.departamento}</option>
                //                     )
                //                 }
                //             </optgroup>
                //         ) 
                //       }
                //     </select>
                //   <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                // </Form.Group>
}


            {
              values.uuid_nomina === "50599e55-84f7-471d-bb3a-f5ff4b6d9b40" && ( // OTRO

                    <div className="mb-3" >
                            <div  >
                               <Form.Group >
                                  <Form.Label>Clasificación de la Plaza</Form.Label>
                                </Form.Group>
                            </div>

 
                                          <Form.Check 
                                            name="idsubnomina"
                                            inline
                                            checked={values.idsubnomina===4}
                                            onChange={(e)=>{setFieldValue('idsubnomina',4)}}
                                            type="radio"
                                            label={`PRESIDENCIA`}
                                            id={`disabled-default-PRESIDENCIA`}
                                          />
                                          <Form.Check 
                                            name="idsubnomina"
                                            inline
                                            checked={values.idsubnomina===10}
                                            onChange={(e)=>{setFieldValue('idsubnomina',10)}}
                                            type="radio"
                                            label={`EN TRAMITE DE JUBILACION`}
                                            id={`disabled-default-JUBILACION`}
                                          />
                                          <Form.Check 
                                            name="idsubnomina"
                                            inline
                                            checked={values.idsubnomina===9}
                                            onChange={(e)=>{setFieldValue('idsubnomina',9)}}
                                            type="radio"
                                            label={`PENSIONADOS`}
                                            id={`disabled-default-PENSIONADOS`}
                                          />
                                          <Form.Check 
                                            name="idsubnomina"
                                            inline
                                            checked={values.idsubnomina===5}
                                            onChange={(e)=>{setFieldValue('idsubnomina',5)}}
                                            type="radio"
                                            label={`PENSION POR DEP ECONÓMICO`}
                                            id={`disabled-default-DEP`}
                                          />
                                          <Form.Check 
                                            name="idsubnomina"
                                            inline
                                            checked={values.idsubnomina===6}
                                            onChange={(e)=>{setFieldValue('idsubnomina',6)}}
                                            type="radio"
                                            label={`PENSION POR JUBILACIÓN`}
                                            id={`disabled-default-jubilacion`}
                                          />
                                          <Form.Check 
                                            name="idsubnomina"
                                            inline
                                            checked={values.idsubnomina===7}
                                            onChange={(e)=>{setFieldValue('idsubnomina',7)}}
                                            type="radio"
                                            label={`PENSION POR ORFANDAD`}
                                            id={`disabled-default-ORFANDAD`}
                                          />
                                          <Form.Check 
                                            name="idsubnomina"
                                            inline
                                            checked={values.idsubnomina===8}
                                            onChange={(e)=>{setFieldValue('idsubnomina',8)}}
                                            type="radio"
                                            label={`PENSION POR VIUDEZ`}
                                            id={`disabled-default-VIUDEZ`}
                                          />

                    </div>

                )
            }



            {
              values.uuid_nomina === "09407ce0-0b8e-409a-9cf3-2dc29c9d9e9d" && ( // BASE

                    <div className="mb-3" >
                            <div  >
                               <Form.Group >
                                  <Form.Label>Clasificación de la Plaza</Form.Label>
                                </Form.Group>
                            </div>

 
                                          <Form.Check 
                                            name="idsubnomina"
                                            inline
                                            value={1}
                                            checked={values.idsubnomina===1}
                                            onChange={(e)=>{setFieldValue('idsubnomina',1)}}
                                            type="radio"
                                            label={`BASE SINDICALIZADOS SSTSA`}
                                            id={`disabled-default-SSTSA`}
                                          />
                                          <Form.Check 
                                            name="idsubnomina"
                                            inline
                                            value={2}
                                            checked={values.idsubnomina===2}
                                            onChange={(e)=>{setFieldValue('idsubnomina',2)}}
                                            type="radio"
                                            label={`BASE SINDICALIZADOS SUTSGM`}
                                            id={`disabled-default-SUTSGM`}
                                          />
                                          <Form.Check 
                                            name="idsubnomina"
                                            inline
                                            checked={values.idsubnomina===12}
                                            onChange={(e)=>{setFieldValue('idsubnomina',12)}}
                                            type="radio"
                                            label={`BASE SINDICALIZADOS FUERTEC`}
                                            id={`disabled-default-FUERTEC`}
                                          />

                    </div>

                )
            }




          <Row>



            <Form.Group as={Col} md="8" className="mb-3" controlId="rfc">
              <Form.Label>Selección de Puesto </Form.Label>
                <select className="form-select" value={values.uuid_puesto}  name="uuid_puesto" onChange={(e)=>{ setFieldValue('uuid_puesto', (e.target.value) ); setPuestoSeleccion(JSON.parse(e.target.value)) }}>
                  <option value="" disabled>Selecciona un puesto para el trabajador</option>
                  { 
                    grupos_funcional.map( (grupo_funcional:any)=> 
                        <optgroup key={ Math.random().toString(36).substr(2, 9) } label={grupo_funcional.nivel_jerarquico}>
                            {
                              grupo_funcional.puestos.map( (puesto:any) =>
                                  <option key={ Math.random().toString(36).substr(2, 9) } value={JSON.stringify(puesto)}>{grupo_funcional.id} -{puesto.clave_puesto} - {puesto.puesto} ({puesto.idgrupo_funcional.grupo_funcional})</option>
                                )
                            }
                        </optgroup>
                    ) 
                  }
                </select>
              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            </Form.Group>


                <Form.Group as={Col} md="4" className="mb-3" controlId="sueldo">
                  <Form.Label>Sueldo</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    disabled={true}
                    name="sueldo"
                    value={values.sueldo}
                    onChange={(e)=>{ setFieldValue('sueldo', e.target.value ) }}
                    isInvalid={!!errors.sueldo}
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>

            </Row>





            <div className="row">
              <div className="col">
                {
                  // JSON.stringify(puesto_seleccionado)
                }

                {
                  values.uuid_puesto!=='' && (

                          <div className="alert alert-dark" role="alert">
                              <p className="nm"><small>Puesto</small></p>
                              <h4>{puesto_seleccionado.puesto}</h4>
                              <h6>$ {puesto_seleccionado.total_percepciones_asegurado_min} MIN - {puesto_seleccionado.total_percepciones_asegurado_max} MAX</h6>
                              <div className="row">
                                <div className="col-4">
                                  <small>Sueldo / Honorarios</small>
                                  <p className="nm">$ {puesto_seleccionado.sueldo_base_min} MIN - $ {puesto_seleccionado.sueldo_base_max} MAX </p>
                                  <small><b>P001</b></small>
                                </div>
                                <div className="col-4">
                                  <small>Salario Extraordinario</small>
                                  <p className="nm">$ {puesto_seleccionado.compensacion_max} MAX </p>
                                  <small><b>P003</b></small>
                                </div>
                                <div className="col-4">
                                  <small>PSM</small>
                                  <p className="nm">$ {puesto_seleccionado.psm} MAX </p>
                                  <small><b>P025</b></small>
                                </div>
                              </div>
                          </div>


                    )
                }


              </div>
            </div>









             <Row>

                <Form.Group as={Col} md="3" className="mb-3" controlId="P001">
                  <Form.Label>P001</Form.Label>
                  <Form.Control
                    type="number"
                    required
                    disabled={values.uuid_puesto===''}
                    name="p001"
                    value={values.p001}
                    onChange={(e)=>{  sumaPes( (e.target.value), (values.p002), (values.p003), (values.p025), setFieldValue ); setFieldValue('p001', e.target.value ); }}
                    isInvalid={!!errors.p001}
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <div className="feedback">
                      <small>Sueldo / Honorarios</small>
                    </div>
                </Form.Group>

                <Form.Group as={Col} md="3" className="mb-3" controlId="P002">
                  <Form.Label>P002</Form.Label>
                  <Form.Control
                    type="number"
                    required
                    disabled={values.uuid_puesto===''}
                    name="p002"
                    value={values.p002}
                    onChange={(e)=>{  sumaPes( (values.p001), (e.target.value), (values.p003), (values.p025), setFieldValue ); setFieldValue('p002', e.target.value ); }}
                    isInvalid={!!errors.p002}
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <div className="feedback">
                      <small>Quinquenio</small>
                    </div>
                </Form.Group>

                <Form.Group as={Col} md="3" className="mb-3" controlId="P003">
                  <Form.Label>P003</Form.Label>
                  <Form.Control
                    type="number"
                    required
                    disabled={values.uuid_puesto===''}
                    name="p003"
                    value={values.p003}
                    onChange={(e)=>{  sumaPes( (values.p001), (values.p002), (e.target.value), (values.p025), setFieldValue ); setFieldValue('p003', e.target.value ); }}
                    isInvalid={!!errors.p003}
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <div className="feedback">
                      <small>Salario Extraordinario</small>
                    </div>
                </Form.Group>

                <Form.Group as={Col} md="3" className="mb-3" controlId="p025">
                  <Form.Label>P025</Form.Label>
                  <Form.Control
                    type="number"
                    required
                    disabled={values.uuid_puesto===''}
                    name="p025"
                    value={values.p025}
                    onChange={(e)=>{  sumaPes( (values.p001), (values.p002), (values.p003), (e.target.value), setFieldValue ); setFieldValue('p025', e.target.value ); }}
                    isInvalid={!!errors.p025}
                  />
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <div className="feedback">
                      <small>PSM</small>
                    </div>
                </Form.Group>


             </Row>



             <Row>
                <Form.Group as={Col} md="3"  className="mb-3">
                  <Form.Check
                    name="alta_imss"
                    checked={values.alta_imss}
                    label="Registro en el IMSS"
                    onChange={handleChange}
                    isInvalid={!!errors.alta_imss}
                    feedbackType="invalid"
                    id="validationFormik1"
                  />
                </Form.Group>
            </Row>




          <Row>
            <Col md="4">
              <Form.Label>Fecha de Ingreso</Form.Label>
              <DatePicker selected={values.fecha_ingreso} className="form-control" maxDate={new Date()} inline dateFormat="yyyy-MM-dd" onChange={(date) => setFieldValue('fecha_ingreso', date ) } />
            </Col>
          </Row>



          </Row>

          
        </Form>
      )}
    </Formik>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="success" form='my-form' type="submit">Guardar <i className="fa fa-save"/></Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default ModalEditarInformacionNominaEmpleado;